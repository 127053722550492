
.dentalSuppliesPage .shopSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
}

.dentalSuppliesPage .productsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 16px;
}

.dentalSuppliesPage .productContainer {
	padding: 16px;
	max-width: 300px;
	border: 1px solid black;
	border-radius: var(--global-border-radius);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dentalSuppliesPage .productImage {
	max-width: 250px;
}

.dentalSuppliesPage .productName {
	text-align: center;
	text-decoration: none!important;
	border: none;

}