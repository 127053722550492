footer {
	text-align: center;
	background-color: var(--primary-color-light);
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding-bottom: 32px;
}

.footer {
	padding-top: 32px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 32px;
}

.footer-contacts, 
.footer-map {
	width: 100%;
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.footer a {
	color: black;
}

.footer-map {
	height: 300px;
	border: 1px solid black;
}

@media screen and (min-width: 768px) {
	.footer-contacts, 
	.footer-map {
		max-width: 40vw;
	}
}

footer h1 {
	padding-bottom: 24px;
}


.footer-contactText {
	color: black;
}

.footerMenu ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	text-align: left;
}

.footerMenu li {
	width: 50%;
	list-style: none;
}

.footerMenu-link {
	text-decoration: none;
	color: black;
}

@media screen and (min-width: 768px) {

	.footerMenu ul {
		text-align: center;
		flex-wrap: nowrap;
		gap: 16px;
		justify-content: space-around;
	}

	.footerMenu li {
		width: auto;
	}

}