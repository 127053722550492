
/*	
	------------------------------
	Hero Section 
	------------------------------
*/

.homePage h1 {
	padding-bottom: 0;
}

.homePage .heroSection {
	display: flex;
	align-items: center;
	/* flex-wrap: wrap; */
	/* flex-direction: column; */
	height: 90vh;
	padding-top: 64px;
}

.homePage .heroInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
	gap: 32px;
}

.homePage .heroImageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.homePage .heroImage {
	width: 100%;
}

.homePage .heroSection {
	flex-direction: row;
	align-items: center;
}

.homePage .heroInfo {
	width: 50%;
}

.homePage .heroImageContainer {
	height: 70%;
	width: 50%;
}

/* Responsiveness */

@media screen and (max-width: 768px) {
	.homePage .heroInfo {
		width: 100%;
	}
	.homePage .heroImageContainer {
		display: none;
	}
}

/*	
	------------------------------
	Shop Section 
	------------------------------
*/

.homePage .shopSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
}

.homePage .productsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 16px;
}

.homePage .productContainer {
	padding: 16px;
	max-width: 300px;
	border: 1px solid black;
	border-radius: var(--global-border-radius);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.homePage .productImage {
	max-width: 250px;
}

.homePage .productName {
	text-align: center;
	text-decoration: none!important;
	border: none;

}

/*	
	------------------------------
	Reviews Section 
	------------------------------
*/

.homePage .reviewsContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.homePage .reviewContainer {
	border-radius: 16px;
	border: 1px solid black;
	padding: 16px;
	display: flex;
	gap: 16px;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.homePage .reviewContainer {
		/* flex-direction: column; */
		flex-wrap: wrap;
		justify-content: center;
	}

	.homePage .reviewInfoContainer {
		gap: 32px;
	}
}

.homePage .reviewImageContainer {
	height: 150px;
	flex-basis: 150px;
	flex-shrink: 0;
	overflow: hidden;
	border: 1px solid black;
	border-radius: 50%;
}

.homePage .reviewImage {
	width: 100%;
	object-fit: cover;
}

.homePage .reviewInfoContainer {
	display: flex;
	flex-direction: column;
}

/*	
	------------------------------
	Benefits Section 
	------------------------------
*/

	.homePage .benefitsContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		justify-content: center;
	}

	.homePage .benefitContainer {
		flex-basis: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 16px;
		gap: 16px;
		border: 1px solid black;
		border-radius: var(--global-border-radius);
		transition: var(--global-transition);
	}

	@media screen and (max-width: 768px) {
		.homePage .benefitContainer {
			flex-basis: 100%;
		}
	}

	.homePage .benefitContainer:hover {
		background-color: var(--primary-color-light);
	}

/*	
	------------------------------
	From Zambia Section 
	------------------------------
*/

.homePage .fromZambiaSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
}

.homePage .fromZambiaContainer {
	display: flex;
	align-items: center;
}

.homePage .fromZambiaImage {
	max-width: min(80vw, 500px);
	height: 100%;
}

@media screen and (max-width: 768px) {
	.homePage .fromZambiaContainer {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 32px;
	}
}
