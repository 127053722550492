.contactPage h1 {
	padding-bottom: 64px;
}

.contactPage .contactOptionsContainer {
	display: flex;
	justify-content: space-around;
}

@media screen and (max-width: 768px) {
	.contactPage .contactOptionsContainer {
		flex-wrap: wrap;
		gap: 32px;
	}
}

.contactPage .contactInfoContainer {
	flex-basis: 300px;
}

.contactPage h3:not(:nth-child(1)) {
	padding-top: 32px;
}





/*	
	------------------------------
	Form
	------------------------------
*/

form {

}

/* form h3 {
	margin-bottom: 0;
} */

/* .form-headline {
	text-align: left;
	margin-top: 0;
} */

@keyframes errorMessage-animation {
	from {
		transform: scaleY(0);
		transform-origin: top;
	}
	to {
		transform: scaleY(1);
	}
}
/* 
.form-inputContainer:has(
	.form-input:not(
		:placeholder-shown, :focus, :valid))::after {
	display: block;
	content: attr(errorMessage);
	animation-name: errorMessage-animation;
	animation-duration: 0.5s;
} */

.form-input:not(
		:placeholder-shown, :focus, :valid) {
	border: 2px solid red;
	color: red;
}

.form-input {
	height: 50px;
	width: min(100%, 500px);
	padding: 12px;
	border: 1px solid rgb(0, 0, 0);
	border-radius: var(--global-border-radius);
}

.form-inputContainer-buttonClicked:has(
	.form-input:invalid)::after {
	content: attr(errorMessage);
}

.form-input-buttonClicked:invalid {
	border: 2px solid red;
	color: red;
}

.form .form-checkbox {
	width: 30px;
	height: 30px;
}

.form {
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
}

.form .checkboxAndLabelContainer {
	display: flex;
	gap: 16px;
}

.form .checkbox-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}







