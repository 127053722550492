#blogPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}

.blogPost {
    flex-basis: 100%;
    padding: 16px;
    border: 1px solid black;
    border-radius: var(--global-border-radius);
}

@media screen and (min-width: 768px) {
    .blogPost {
        flex-basis: min(40%, 500px);
    }
}


