/* Variables  */

:root {
	--global-box-shadow: 2px 2px 14px rgb(56, 56, 56);
	--global-horizontal-padding: 6vw;
	--global-vertical-padding: 64px;
	--global-transition: 0.2s ease-out;
	--global-border-radius: 16px;
	--global-transform-scale: 1.1;
	--global-transform: scale(1.07);
	--min-width-laptop-breakpoint: 768px;
	--hamburgerLine-start-position: 16px;
	--hamburgerLine-end-position: 24px;

	--primary-color: #005db6;
	--primary-color-light: #d6e3ff;
	--primary-color-dark: rgb(62, 155, 226);
	--surface: #fdfbff;
	--gradient: 33deg, var(--primary-color-light-2), 80%, var(--primary-color-light);

	--font-family-1: 'Lexend Exa', sans-serif;
	--font-family-2: 'Raleway', sans-serif;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	margin: 0px;
	padding: 0px;
}

header, 
section, 
footer {
	padding-left: var(--global-horizontal-padding);
	padding-right: var(--global-horizontal-padding);
}

section {
	padding-top: var(--global-vertical-padding);
	padding-bottom: var(--global-vertical-padding);
}

/*	
	------------------------------
	Global Typography 
	------------------------------
*/

h1, 
h2, 
h3 {
	color: black;
	font-family: var(--font-family-2);
	line-height: 1.2;
}

h1 {
	font-size: 38px;
	text-align: center;
	font-weight: 900;
	padding-bottom: var(--global-vertical-padding);
}


h2 {
	font-size: 38px;
	text-align: center;
	font-weight: 900;
	padding-bottom: 32px;
}

h3 {
	font-size: 22px;
	text-align: left;
}

p, 
input, 
select,
label, 
ul, 
ol, 
a,
span {
	font-family:  var(--font-family-2);
	font-size: 18px;
	line-height: 1.5;
}

*::after, *::before {
	font-family: var(--font-family-2);
	font-size: 18px;
	line-height: 1.5;
	color: red;
}

.headline {
	text-align: center;
	font-size: 20px;
	font-style: italic;
	padding-bottom: 16px;
}


a {
	color: var(--primary-color-dark);
}

em {
	font-weight: 600;
}

/*	
	------------------------------
	Global Button 
	------------------------------
*/

.button {
	display: flex;
	align-items: center;
	justify-content: center;	
	padding: 16px 0;
	width: clamp(200px, 300px, 400px);
	background-color: var(--primary-color-dark);
	border-radius: var(--global-border-radius);
	border: 1px solid black;
	transition: var(--global-transition);
	font-family: var(--font-family-2);
	text-align: center;
	color: rgb(255, 255, 255);
	font-weight: bold;
	text-decoration: none;
	font-size: 22px;
	cursor: pointer;
}

.button a, .button {
	flex-basis: 100%;
	font-family: var(--font-family-2);
	text-align: center;
	color: rgb(255, 255, 255);
	font-weight: bold;
	text-decoration: none;
	font-size: 22px;
	cursor: pointer;
}

.button:hover {
	transform: var(--global-transform);
	box-shadow: var(--global-box-shadow);
}

/*	
	------------------------------
	Global Image 
	------------------------------
*/

.global-image-preset {
	box-shadow: var(--global-box-shadow);
	border-radius: var(--global-border-radius);
	transition: var(--global-transition);
	
}

.global-image-preset:hover {
	transform: var(--global-transform);
}

/*	
	------------------------------
	Contact and Social Media Icons 
	------------------------------
*/

.contactIcon {
	width: 18px;
}

.contactTextLink {
	text-decoration: none;
	color: black;
}

.socialMedia {
	width: 40px;
	margin: 0 8px;
	transition: var(--global-transition);
}

.socialMedia:hover {
	transform: var(--global-transform);
}
